<template>
  <div class="vi-login">
    <b-spinner
      v-if="showToast"
      variant="primary"
      style="width: 3rem; height: 3rem;"
      label="Large Spinner"
    ></b-spinner>
    <main id="vi-login">
      <svg width="100%" height="450" class="web-bg-svg">
        <rect
          width="150%"
          height="450"
          style="fill:rgb(41, 84, 162);"
          transform="rotate(-13 0,750) skewX(8)"
        ></rect>
      </svg>
      <div class="container divcenter">
        <div class="row mt-5 mb-5">
          <div class="col-md-6">
            <img
              src="../../assets/img/login_3.svg"
              width="510"
              class="img-fluid heroimg mt-4"
              alt="Login"
            />
          </div>
          <div class="col-md-6">
            <div class="card web-login-card p-4 mt-2 mb-5">
              <div class="login-form" v-if="lActive">
                <div class="dispflex">
                  <h1>Login to VIDHYAAN</h1>
                  <!-- <div class="g-signin2" @click.prevent="onSignIn"></div> -->
                  <div class="cardIcn" @click.prevent="backToHome">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 28 28"
                      version="1.1"
                    >
                      <defs>
                        <path d="M0 0L28 0L28 28L0 28L0 0Z" id="path_1" />
                        <clipPath id="mask_1">
                          <use xlink:href="#path_1" />
                        </clipPath>
                      </defs>
                      <g id="Home-icon">
                        <path
                          d="M0 0L28 0L28 28L0 28L0 0Z"
                          id="Background"
                          fill="#FFFFFF"
                          fill-opacity="0"
                          fill-rule="evenodd"
                          stroke="none"
                        />
                        <g clip-path="url(#mask_1)">
                          <path
                            d="M13.9998 0.588379C13.878 0.588379 13.7567 0.62701 13.6531 0.70541L0.773084 10.7581C0.532284 10.9485 0.487528 11.3003 0.677928 11.5467C0.868328 11.7875 1.22012 11.8322 1.46652 11.6418L2.2398 11.0381L2.2398 25.7599C2.2398 26.0679 2.4918 26.3199 2.7998 26.3199L10.6398 26.3199L10.6398 16.2399L17.3598 16.2399L17.3598 26.3199L25.1998 26.3199C25.5078 26.3199 25.7598 26.0679 25.7598 25.7599L25.7598 11.0381L26.5331 11.6418C26.6395 11.7202 26.7566 11.7599 26.8798 11.7599C27.0478 11.7599 27.2097 11.6867 27.3217 11.5467C27.5121 11.3003 27.4673 10.9485 27.2265 10.7581L14.3465 0.70541C14.2429 0.62701 14.1216 0.588379 13.9998 0.588379L13.9998 0.588379ZM19.5996 2.80005L19.5996 3.38849L22.9596 6.00911L22.9596 2.80005L19.5996 2.80005L19.5996 2.80005Z"
                            id="Shape"
                            fill="#000000"
                            fill-rule="evenodd"
                            stroke="none"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <span v-if="serviceError" class="vi-error">{{
                  serviceError
                }}</span>
                <form data-vv-scope="login" class="w-100" autocomplete="off">
                  <!-- autocomplete="off" -->
                  <div class="form-row m-0">
                    <div class="form-group input-group mt-3">
                      <span class="web-has-float-label">
                        <input
                          v-validate="'required'"
                          name="email"
                          class="form-control web-form-input"
                          id="emailid"
                          v-model="email"
                          maxlength="50"
                          placeholder="Email Id"
                          ref="emailidref"
                          autocomplete="off"
                          style="cursor: text;"
                        />
                        <label
                          v-if="errors.first('login.email')"
                          class="vi-error"
                          for="emailid"
                          style="top:-1em"
                          >Email Id is required</label
                        >
                        <label for="emailid" v-else
                          >Email Id <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group mt-2">
                      <span class="web-has-float-label">
                        <input
                          v-validate="'required'"
                          type="password"
                          name="password"
                          placeholder="Password"
                          class="form-control web-form-input"
                          v-model="password"
                          maxlength="50"
                          autocomplete="off"
                          style="cursor: text;"
                        />
                        <label
                          v-if="errors.first('login.password')"
                          class="vi-error"
                          for="pwdid"
                          style="top:-1em"
                          >Password is required</label
                        >
                        <label for="pwdid" v-else
                          >Password <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div style="width:60%;margin:0 auto;">
                      <div
                        class="g-recaptcha w-100 text-center mb-2"
                        id="recaptcha"
                        data-callback="checkResponse"
                        data-sitekey="6Lcs5UMeAAAAAApF9anTicewdEFZQbZesyw5C0VD"
                      ></div>
                    </div>
                    <button
                      @click.prevent="submitLogin"
                      class="web-custbutton mt-2 w-100 ml-1 ml-sm-0"
                    >
                      Login
                    </button>

                    <div>
    <!-- <div class="social-auth">
      <a :href="getGoogleUrl(from)" class="google-auth">
        <img :src="GoogleLogo" alt="Google Logo" />
        <span>Google</span>
      </a>
    </div> -->
  </div>

                  </div>
                </form>
                <div class="row pb-3 no-gutters ">
                  <div class="col-md-6 ml-1 ml-sm-0"></div>
                </div>
                <div class="web-or-border mt-2"></div>
              </div>
              <div class="forgot-password" v-else>
                <h1>Forgot Password?</h1>
                <p class="m-0">
                  Enter the email Address which is associated with you account.
                </p>
                <span v-if="serviceError" class="vi-error">{{
                  serviceError
                }}</span>
                <form class="w-100" novalidate>
                  <div class="form-row m-0">
                    <div class="form-group input-group mt-3">
                      <span class="web-has-float-label">
                        <input
                          type="email"
                          name="email"
                          class="form-control web-form-input"
                          id="uemailid"
                          placeholder="Email Id"
                          v-model="femail"
                          maxlength="50"
                        />
                        <label
                          v-if="femailError"
                          class="vi-error"
                          for="emailid"
                          >{{ femailError }}</label
                        >
                        <label for="uemailid" v-else
                          >Email Id <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <button
                      type="submit"
                      class="web-custbutton mt-3 w-100"
                      @click="submitforgot"
                    >
                      Send
                    </button>

                    <p class="form-group mt-1 w-100 text-center">
                      we will email you a link to reset your password.
                    </p>
                    <span class="d-block text-center w-100"
                      >Go back to
                      <a
                        href="javascript:void(0);"
                        class="forgot-password-link "
                        @click="goback"
                        >Login</a
                      ></span
                    >
                  </div>
                </form>
              </div>
              <div class="mt-2 pt-2 w-100 text-center ">
                <a href="javascript:void(0);" @click="forgotLink"
                  >Forgot Password?</a
                >
                <br />

                Don't have an account?
                <a href="javascript:void(0);" @click="showRegister"
                  >Create Account</a
                >
              </div>
              <div class="mt-3 pt-2 w-100 text-center" v-if="regLinks">
                <label class="web-custradio" style="margin-right:10px;"
                  >School / Institution
                  <input
                    type="radio"
                    name="regRadio"
                    v-model="registerLink"
                    @change="getRegPath"
                    :value="'/register/school'"
                  />
                  <span class="web-checkmark"></span>
                </label>
                <label class="web-custradio" style="margin-right:10px;"
                  >Parent / Self
                  <input
                    type="radio"
                    name="regRadio"
                    v-model="registerLink"
                    @change="getRegPath"
                    :value="'/register/parent'"
                  />
                  <span class="web-checkmark"></span>
                </label>
                <!-- <label class="web-custradio" style="margin-right:10px;"
                  >Job Seeker
                  <input
                    type="radio"
                    name="regRadio"
                    v-model="registerLink"
                    @change="getRegPath"
                    :value="'/register/jobseeker'"
                  />
                  <span class="web-checkmark"></span>
                </label> -->
              </div>
              <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="position: absolute;width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- End #main -->
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "@/utils/secureUI";
//import GoogleLogo from "../../assets/img/Google Signup.svg";
//import GoogleLogo from "../../../src/assets/img/Google Signup.png";
//import { getGoogleUrl } from "../../utils/getGoogleUrl";

export default {
  name: "vi-login",
  components: {
    ViSpinner,
  },
  mounted() {
    this.$refs.emailidref.$el.focus();
  },
  data() {
    return {
      showToast: false,
      email: null,
      emailError: null,
      password: null,
      passwordError: null,
      femail: null,
      femailError: null,
      serviceError: null,
      viLoader: false,
      lActive: true,
      regLinks: null,
      registerLink: null,
      showPassVerificationCard: false,
      resetPassword: "",
      resetConfirmPassword: "",
      username: "",
      recordId: null,
      sitekey: "",
      widgetId1: {},
      studentInformation: {},
    };
   // from = '/';
  },

  created() {
    this.initReCaptcha();
  },

  methods: {
    redirectRegister() {
      if (this.$route.path !== "/register")
        this.$router.push({ path: "/register" });
    },
    async submitLogin(e) {
      let isFormValid = false;
      e.preventDefault();
      this.serviceError = null;
      this.$toasted.clear();

      await this.$validator.validateAll("login").then((result) => {
        isFormValid = result;
      });
      if (isFormValid) {
        const response = window.grecaptcha.getResponse();
        if (response) {
          let requestBody = {
            email: this.email,
            password: this.password,
          };
          this.viLoader = true;
          ViService.viPost("/auth/signin", requestBody)
            .then((res) => {
              this.viLoader = null;
              if (res.isSuccess) {
                (this.showToast = true), 
                localStorage.setItem("user", res.data);

                let userData = secureUI.sessionGet("user");
               
                if(userData.userDetails.branch && userData.userDetails.branch._id){
                    localStorage.setItem("currentBranch", userData.userDetails.branch._id);
                }else{
                   localStorage.setItem("currentBranch", null);
                }
                

                this.$store.dispatch('setUserDetail', userData.userDetails);
                
                if (userData.userDetails.profile.name == "School Admin") {
                  this.$store.dispatch('setCurrentAcademicYear', userData.userDetails.account.currentAcademicYear);

                  this.$store.dispatch(
                    "setCurrentPlanDetail",
                    userData.subscription
                  );

                  if (userData.userDetails.account.version == "freeVersion") {
                    localStorage.setItem("activeTab", "/enrollment/school");
                    localStorage.setItem("previousTab", "/enrollment/school");
                    this.$router.push("/enrollment/school");
                  } else {
                    if (
                      userData.subscription.status == "dunning" ||
                      userData.subscription.status == "unpaid" ||
                      userData.subscription.status == "cancelled"
                    ) {
                      localStorage.setItem(
                        "activeTab",
                        "/admission/admissionSettings"
                      );
                      this.$router.push("/admission/admissionSettings");
                    } else {
                      this.getAllObjectCounts()
                     
                    }
                  }
                  localStorage.setItem("zohoBotJWT", userData.zohoJWT);
                  localStorage.setItem("zohoBotNonce", userData.zohoNonce);
                } else if (
                  userData.userDetails.profile.name == "Vidhyaan Admin"
                ) {
                  localStorage.setItem("superAdminuser", res.data);
                  localStorage.setItem("activeTab", "/admin-panel");
                  localStorage.setItem("previousTab", "/admin-panel");
                  this.$router.push("/admin-panel");
                } else if (
                  userData.userDetails.profile.name == "Teacher"
                ) {
                  this.$store.dispatch('setCurrentAcademicYear', userData.userDetails.account.currentAcademicYear);

                  localStorage.setItem("activeTab", "/teacher/home");
                  localStorage.setItem("previousTab", "/teacher/home");
                  this.$router.push("/teacher/home");
                } else {
                  if (userData.userDetails.isShowStudentProfile) {
                    this.getStudentAndSchoolInfo();
                  } else {
                    localStorage.setItem("activeTab", "/parent/search/home");
                     localStorage.setItem("previousTab", "/parent/search/home");
                    this.$router.push("/parent/search/home");
                  }
                }
                this.showToast = false;
              } else {
                if (
                  !res.isSuccess &&
                  res.message == "Please Reset Your Password"
                ) {
                  this.$toasted.error(res.message);

                  this.resetPassword = "";
                  this.resetConfirmPassword = "";
                  this.recordId = res.data._id;
                  this.username = res.data.username;
                  this.showPassVerificationCard = true;
                } else {
                  this.$toasted.error(res.message);
                }
              }
            })
            .catch((e) => {
              (this.showToast = false), (this.viLoader = null);
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        } else {
          this.$toasted.error("Please Verify Captcha");
        }
      }
    },
    async getAllObjectCounts() {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
           // this.viLoader = true;

            //let isDataFetch = this.$store.getters.getFetchAdmissionObjCounts
          //   if(!isDataFetch || isDataFetch == undefined){
                const response = await ViService.viXGet(
                  "/admission/getAllObjectCounts",
                  userData.token
                );
                if (response.isSuccess) {
                  const objResult = secureUI.secureGet(response.data);
                  
                 if(objResult.student && objResult.student > 0){
                  localStorage.setItem("activeTab", "/school/admin/home");
                       localStorage.setItem("previousTab", "/school/admin/home");
                      this.$router.push("/school/admin/home");
                 }else{
                  localStorage.setItem("activeTab", "/admission/home");
                       localStorage.setItem("previousTab", "/admission/home");
                      this.$router.push("/admission/home");
                 }
                      
                  // this.$store.dispatch('setAdmissionObjCOunt', {

                  // academicYear : objResult.academicYear ? objResult.academicYear : 0,
                  // admission : objResult.admission ? objResult.admission : 0,
                  // class : objResult.class ? objResult.class : 0,
                  // departMent : objResult.departMent ? objResult.departMent : 0,
                  // student : objResult.student ? objResult.student : 0,
                  // teacher : objResult.teacher ? objResult.teacher : 0,
                  // schoolType : objResult.schoolType ? objResult.schoolType : 0,
                  // term : objResult.term ? objResult.term : 0,
                  // grade : objResult.grade ? objResult.grade : 0,
                  // admins : adminCount,
                  // section: objResult.section ? objResult.section : 0,
                  // subject: objResult.subject ? objResult.subject : 0
                  // });
                  // this.$store.dispatch('setFetchAdmissionObjCount',true)
                  

                  //this.$toasted.success(response.message);
                } else {
                  this.$toasted.error(response.message);
                  if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
            }
             //   }

             }
             //this.viLoader = false;

          }
        },
    getStudentAndSchoolInfo() {
      let userData = secureUI.sessionGet("user");

      ViService.viXGet(
        "/contact/getStudentAndSchoolDetails/parent/portal",
        userData.token
      )
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              const studentInformationDetails = data;
              const studentProfiles = studentInformationDetails.filter(
                (x) => x.type == "Contact"
              );
              this.studentInformation = studentProfiles[0];

              localStorage.setItem(
                "activeTab",
                `/parent/student/home/${this.studentInformation._id}`
              );

              this.$router.push(
                `/parent/student/home/${this.studentInformation._id}`
              );
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    async initReCaptcha() {
      setTimeout(() => {
        if (!window.grecaptcha || !window.grecaptcha.render) {
          initReCaptcha();
        } else {
          window.grecaptcha.render("recaptcha", {
            sitekey: "6Lcs5UMeAAAAAApF9anTicewdEFZQbZesyw5C0VD",
            theme: "light",
          });
        }
      }, 500);
    },

    checkResponse() {
      google_recpach = grecaptcha.getResponse(this.live_google_recpachid);
    },

    forgotLink() {
      this.$router.push("/forgotPassword");
      this.clearform();
    },
    backToHome() {
      this.$router.push("/");
    },

    goback() {
      this.lActive = true;
      this.clearform();
    },
    submitforgot(e) {
      e.preventDefault();
      this.serviceError = null;
      this.$toasted.clear();

      let requestBody = {
        emailId: this.femail,
      };
      this.viLoader = true;
      ViService.viGet("/forgot-password", requestBody)
        .then((res) => {
          this.viLoader = null;
          if (res.isSuccess) {
            this.$toasted.success(res.message);
            this.clearform();
          } else {
            this.$toasted.error(res.message);
          }
        })
        .catch((e) => {
          this.viLoader = null;
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    clearform() {
      this.email = null;
      this.emailError = null;
      this.password = null;
      this.passwordError = null;
      this.femail = null;
      this.femailError = null;
      this.serviceError = null;
    },
    showRegister() {
      this.regLinks == null ? (this.regLinks = true) : (this.regLinks = null);
    },
    getRegPath(e) {
      let vipath = e.target.value;
      setTimeout(() => {
        this.$router.push({ path: vipath });
      }, 100);
    },
    getGoogleUrl(from) {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
  
    const options = {
      redirect_uri: 'http://localhost:3000/app/oauth/google',
      client_id: '981123153215-bd1jukea25uuc88dg8p5q713kv7g52q2.apps.googleusercontent.com',
      access_type: 'offline',
      response_type: 'code',
      prompt: 'consent',
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ].join(' '),
      state: '/',
    };
  
    const qs = new URLSearchParams(options);
  
    return `${rootUrl}?${qs.toString()}`;
}
  },
};
</script>

<style lang="css" scoped>
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 10px 0;
  margin-top: 70px;
}
.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 300;
  color: #fff;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
  color: #e7e7e7;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4f5a62;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f7f7f7;
}

.web-section-title {
  text-align: center;
  padding-bottom: 30px;
}

.web-section-title h2 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}
.web-section-title h2 span {
  color: #2954a2;
}

.web-section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.web-section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #2954a2;
  bottom: 0;
  left: calc(50% - 20px);
}

.web-section-title p {
  margin-bottom: 0;
}
.web-whitebg {
  background: #fff;
  border-radius: 6px;
  transition: 0.3s all;
  border-top: 2px solid #2954a2;
}
.web-whitebg:hover {
  box-shadow: 0 0 3px #ccc;
}
.web-row-flex {
  display: flex;
  flex-wrap: wrap;
}
.content {
  height: 100%;
  padding: 12px;
}
.fit-image {
  width: 100%;
  object-fit: cover;
}
.logincont span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 0.85rem;
}
.web-loginbg {
  background: url("../../assets/images/login/loginbg.jpg");
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-size: cover;
  background-position: center;
}
.line {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin-top: 10px;
  position: relative;
}
.line span.or {
  width: 10%;
  font-weight: bold;
  position: absolute;
  top: -10px;
  background: #fff;
  text-align: center;
}
.fieldcontent {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 10px;
}
.vi-form-control {
  border: none;
  border-bottom: 1px solid #2954a2;
  border-radius: 0px;
}
</style>
